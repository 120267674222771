.App {
  text-align: center;
}

.head {
  font-size: calc(25px + 2vmin);
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
}

.desc {
  font-family: 'Helvetica Neue', sans-serif;
  font-style: italic;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #DBE2E9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
